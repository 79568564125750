import "./App.css";
//@ts-ignore
import React, { useEffect, useState } from "react";
import { vnpsdkapi } from ".";

var i = 0;

function App() {
  useEffect(() => {}, []);

  const [pickAPhotoImageBase64, setPickAPhotoImageBase64] =
    useState<String>("");

  const [captureAPhotoImageBase64, setCaptureAPhotoImageBase64] =
    useState<String>("");

  console.log("rerender app: " + i);

  i++;

  return (
    <div className="App">
      <header className="App-header">
        <button
          onClick={() => {
            vnpsdkapi._backApp();
          }}
        >
          {"back app"}
        </button>
        <button
          onClick={() => {
            vnpsdkapi._getDeviceLocation();
          }}
        >
          {"get device location"}
        </button>
        <button
          onClick={() => {
            vnpsdkapi._getLocationAddress();
          }}
        >
          {"get device address"}
        </button>
        <button
          onClick={() => {
            vnpsdkapi._pickAContact();
          }}
        >
          {"pick a contact"}
        </button>
        <button
          onClick={() => {
            vnpsdkapi._saveImage();
          }}
        >
          {"save image"}
        </button>
        <button
          onClick={() => {
            vnpsdkapi._shareImage();
          }}
        >
          {"share images"}
        </button>
        <button
          onClick={() => {
            vnpsdkapi._openDeviceSetting();
          }}
        >
          {"open device setting"}
        </button>
        <button
          onClick={() => {
            vnpsdkapi._storage();
          }}
        >
          {"storage"}
        </button>
        <button
          onClick={() => {
            vnpsdkapi._openBrowser();
          }}
        >
          {"open browser"}
        </button>
        <button
          onClick={() => {
            vnpsdkapi._pickAPhoto((data) => {
              setPickAPhotoImageBase64(data);
            });
          }}
        >
          {"pick a photo"}
          {/* @ts-ignore */}
          <img width={50} height={50} src={pickAPhotoImageBase64} />
        </button>

        <button
          onClick={() => {
            vnpsdkapi._captureAPhoto((data) => {
              setCaptureAPhotoImageBase64(data);
            });
          }}
        >
          {"capture a photo"}
          {/* @ts-ignore */}
          <img width={50} height={50} src={captureAPhotoImageBase64} />
        </button>

        <button
          onClick={() => {
            vnpsdkapi._openMap();
          }}
        >
          {"open map"}
        </button>
        <button
          onClick={() => {
            vnpsdkapi._getDeviceInfo();
          }}
        >
          {"get device info"}
        </button>
        <button
          onClick={() => {
            vnpsdkapi._payment();
          }}
        >
          {"payment"}
        </button>
        <button
          onClick={() => {
            vnpsdkapi._emit();
          }}
        >
          {"emit"}
        </button>
      </header>
    </div>
  );
}

export default App;
