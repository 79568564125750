import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App.tsx";
import reportWebVitals from "./reportWebVitals";

import eruda from "eruda";
import VNPSDKAPI from "./VNPSDKAPI.tsx";
let el = document.createElement("div");
document.body.appendChild(el);

eruda.init({
  container: el,
  tool: ["console", "elements"],
});

export const vnpsdkapi = new VNPSDKAPI();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
